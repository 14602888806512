import { inject, Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { PlaceholderSectionElementMap } from "../models/editor-with-section/placeholder-section";

/**
 * utilizzo:
 * | placeholderResolver : MAPPA di tipo {placeholder: "$$$$$$$", value: "VALORE_DA_INSERIRE"}
 */
@Pipe({
  name: 'placeholderResolver',
  standalone: true
})
export class SectionPlaceholderResolver implements PipeTransform {
  private sanitizer: DomSanitizer = inject(DomSanitizer);

  transform(htmlText?: string, mapValues: PlaceholderSectionElementMap[] = []): SafeHtml {
    if(!htmlText) return '';

    let result = htmlText;
    for (const element of mapValues) {
      result = result.replaceAll(element.placeholder, element.value)
    }
    return this.sanitizer.bypassSecurityTrustHtml(result)
  }
}
